import { cva } from 'class-variance-authority'
import type { VariantProps } from 'class-variance-authority'

export const expanderCva = cva(undefined, {
  variants: {
    iconColor: {
      blue: 'text-[#002B5F]',
      lightblue: 'text-[#537390]',
      white: 'text-white',
      gray: 'text-[#5A5C5E]'
    },
    backgroundColor: {
      white: 'from-white',
      black: 'from-black',
      primary: 'from-primary'
    }
  },
  defaultVariants: {
    iconColor: 'blue',
    backgroundColor: 'black'
  }
})

export type ExpanderProps = VariantProps<typeof expanderCva>
